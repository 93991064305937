import { Image as DeckardImage } from '@components/image';
import { Group } from '@components/layout';
import { OptionsMenu } from '@components/menu';
import { useEditWorkspaceThumbnailMutation } from '@generated/UseGraphqlHooks';
import { useTheme } from '@mui/material';
import { useNotifications } from '@notifications/Notifications';
import { DatasetsDetailsImagesSliderInferenceOverlay } from './DatasetsDetailsImagesSliderInferenceOverlay';
import { DatasetsDetailsImagesSliderOverlay } from './DatasetsDetailsImagesSliderOverlay';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';

export const ImageViewer = ({ imageIndex, url, workspaceId, onPrevClick, onNextClick }) => {
  const { palette } = useTheme();
  const { groundTruthSettings, groundTruthData } = useImageOverlayContext();

  const { useAsyncNotification } = useNotifications();
  const [editWorkspaceThumbnail] = useEditWorkspaceThumbnailMutation();

  const handleSetThumbnail = useAsyncNotification(
    'You have successfully changed the workspace thumbnail',
    async (url: string) => {
      await editWorkspaceThumbnail({
        variables: {
          workspaceId,
          thumbnail: url,
        },
      });
    },
  );

  return (
    <Group height="100%" width="100%" sx={{ position: 'relative', flex: 1 }}>
      <DeckardImage
        url={url}
        onPrevClick={onPrevClick}
        onNextClick={onNextClick}
        overlaySVG={(imageDimensions, imageRatio) => (
          <>
            <DatasetsDetailsImagesSliderOverlay
              imageIndex={imageIndex}
              renderErrors={null}
              setRenderErrors={null}
            />
            <DatasetsDetailsImagesSliderInferenceOverlay
              index={0}
              imageDimensions={imageDimensions}
              imageRatio={imageRatio}
            />
            <DatasetsDetailsImagesSliderInferenceOverlay
              index={1}
              imageDimensions={imageDimensions}
              imageRatio={imageRatio}
            />
          </>
        )}
        overlayStyle={
          groundTruthData &&
          groundTruthSettings.mode === 'segmentation' &&
          groundTruthSettings.segmentationMask // &&
            ? { backgroundColor: 'black' }
            : {}
        }
      />
      <OptionsMenu
        sx={{
          position: 'absolute',
          right: 0,
          top: 8,
          borderRadius: '20px',
          backgroundColor: palette.background.default,
        }}
        items={[
          {
            label: 'Set workspace thumbnail',
            onClick: () => handleSetThumbnail(url),
          },
        ]}
        menuProps={{ transformOrigin: { vertical: 'top', horizontal: 'right' } }}
      />
    </Group>
  );
};

import { ChevronDownIcon } from '@assets/icons';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { Box, Collapse, Divider, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';

const getConfidenceDisplay = (confidenceString: string) => {
  const confidence = Number(confidenceString);
  if (!confidence) {
    return '-';
  }
  return `${(confidence * 100).toFixed(2)}%`;
};

type DatasetsDetailsImagesSliderOverlayDetailsProps = {
  image: string;
};

export const DatasetsDetailsImagesSliderOverlayDetails = ({
  image,
}: DatasetsDetailsImagesSliderOverlayDetailsProps) => {
  const { palette } = useTheme();
  const {
    datasetInferences,
    groundTruthSettings,
    groundTruthData,
    inferenceSettings,
    inferenceData,
  } = useImageOverlayContext();
  const [expanded, setExpanded] = useState([true, true, true]);
  return (
    <Collapse
      in={groundTruthSettings.visible || inferenceSettings.some((layer) => layer.visible)}
      orientation="horizontal"
    >
      <Group gap={2} height="100%" ml={2}>
        <Divider flexItem orientation="vertical" />
        <Stack
          height="100%"
          p={3}
          bgcolor={palette.background.default}
          width="300px"
          gap={4}
          sx={{ overflowX: 'hidden' }}
        >
          <Typography>{image}</Typography>
          <Stack gap={2}>
            <SectionHeader
              title="Ground truth"
              count={groundTruthData.length}
              color={groundTruthSettings.color}
              open={expanded[0]}
              onToggle={() => setExpanded((prev) => [!prev[0], prev[1], prev[2]])}
            />
            <Collapse in={expanded[0]}>
              <Stack gap={2} pl={8}>
                {groundTruthData.map((annotation, index) => (
                  <Typography key={annotation.id} variant="caption2">
                    {annotation.class}
                  </Typography>
                ))}
              </Stack>
            </Collapse>
          </Stack>
          {inferenceSettings.map((settings, index) =>
            settings.inferenceId ? (
              <Stack key={settings.inferenceId} gap={2}>
                <SectionHeader
                  title={
                    datasetInferences.find((i) => i.inferenceId === settings.inferenceId)?.model
                  }
                  count={
                    inferenceData[settings.inferenceId]?.filter(
                      (inference) =>
                        (Number(inference?.confidence) || 0) * 100 >= settings.confidenceThreshold,
                    )?.length || '-'
                  }
                  color={settings.color}
                  open={expanded[index + 1]}
                  onToggle={() =>
                    setExpanded((prev) => {
                      const next = [...prev];
                      next[index + 1] = !prev[index + 1];
                      return next;
                    })
                  }
                />
                <Collapse in={expanded[index + 1]}>
                  <Stack gap={2} pl={8}>
                    {inferenceData[settings.inferenceId]
                      ?.filter(
                        (inference) =>
                          (Number(inference?.confidence) || 0) * 100 >=
                          settings.confidenceThreshold,
                      )
                      .map((inference, idx) => (
                        <Group key={idx} alignItems="center" justifyContent="space-between" gap={2}>
                          <Typography
                            variant="caption2"
                            handleOverFlow
                            tooltipProps={{ variant: 'secondary' }}
                          >
                            {inference.className}
                          </Typography>
                          <Typography variant="caption2">
                            {getConfidenceDisplay(inference.confidence)}
                          </Typography>
                        </Group>
                      ))}
                  </Stack>
                </Collapse>
              </Stack>
            ) : null,
          )}
        </Stack>
      </Group>
    </Collapse>
  );
};

const SectionHeader = ({
  color,
  count,
  open,
  title,
  onToggle,
}: {
  color: string;
  count: number | string;
  open: boolean;
  title: string;
  onToggle: () => void;
}) => {
  return (
    <Group
      alignItems="center"
      justifyContent="space-between"
      sx={{ '&:hover': { cursor: 'pointer' } }}
      onClick={onToggle}
    >
      <Group alignItems="center" gap={2}>
        <ChevronDownIcon
          style={{
            transition: 'all 0.2s',
            transform: !open ? 'rotate(0deg)' : 'rotate(180deg)',
          }}
        />
        <Box
          sx={{
            height: '16px',
            width: '16px',
            borderRadius: 1,
            backgroundColor: color,
          }}
        />
        <Typography variant="subtitle2">{title}</Typography>
      </Group>
      <Typography variant="subtitle2">{count}</Typography>
    </Group>
  );
};

import { PlusIcon } from '@assets/icons';
import { Button } from '@components/button';
import { Divider } from '@mui/material';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';
import { GroundTruthSettingsRow } from './GroundTruthSettingsRow';
import { InferenceSettingsRow } from './InferenceSettingsRow';

export const OverlayControls = () => {
  const { inferenceSettings, addInferenceLayer } = useImageOverlayContext();
  return (
    <>
      <GroundTruthSettingsRow />
      <Divider />
      {inferenceSettings.map((_, index) => (
        <InferenceSettingsRow key={index} index={index} />
      ))}
      {inferenceSettings.length < 2 && (
        <Button
          startIcon={PlusIcon}
          disabled={inferenceSettings.length >= 2}
          onClick={() => addInferenceLayer()}
          sx={{ my: 1.25 }}
        >
          Add inference layer
        </Button>
      )}
    </>
  );
};

import { getContrastTextColor } from '@helper-functions/color';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';

export const DatasetsDetailsImagesSliderInferenceOverlay = ({
  imageDimensions,
  imageRatio,
  index,
}: {
  imageDimensions: [number, number];
  imageRatio: [number, number];
  index: number;
}) => {
  const { inferenceSettings, inferenceData } = useImageOverlayContext();

  if (!inferenceSettings || index >= inferenceSettings.length) {
    return null;
  }
  const settings = inferenceSettings[index];
  const data = inferenceData[settings.inferenceId];

  if (!data) {
    return null;
  }

  const labelHeight = 24 * imageRatio[1];

  return (
    <>
      {data.map(({ bbox, className, confidence }, index) => {
        if (!bbox) {
          return null;
        }
        if (!settings?.visible) {
          return null;
        }
        if (parseFloat(confidence) * 100 < settings?.confidenceThreshold) {
          return null;
        }
        return (
          <g key={index} width={bbox[2]} overflow="visible">
            <rect
              x={bbox[0] + settings?.strokeWidth / 4}
              y={bbox[1] + settings?.strokeWidth / 4}
              width={bbox[2] - settings?.strokeWidth / 2}
              height={bbox[3] - settings?.strokeWidth / 2}
              fill="none"
              stroke={settings?.color}
              strokeWidth={settings?.strokeWidth / 2}
            />
            {settings?.showLabels && (
              <foreignObject
                x={
                  bbox[1] - labelHeight < 0 && bbox[3] + labelHeight > imageDimensions[1]
                    ? bbox[0] + settings.strokeWidth / 2
                    : bbox[0]
                }
                y={
                  bbox[1] - labelHeight < 0
                    ? bbox[3] + labelHeight > imageDimensions[1]
                      ? bbox[1] + settings.strokeWidth / 2
                      : bbox[3]
                    : bbox[1] - labelHeight
                }
                height={`${labelHeight}px`}
                width={
                  bbox[1] - labelHeight < 0 && bbox[3] + labelHeight > imageDimensions[1]
                    ? bbox[2] - settings.strokeWidth
                    : bbox[2]
                }
                overflow="visible"
              >
                <div
                  style={{
                    backgroundColor: settings?.color,
                    gap: `${4 * imageRatio[0]}px`,
                    justifyContent: 'space-between',
                    paddingLeft: 8 * imageRatio[0],
                    paddingRight: 8 * imageRatio[0],
                    position: 'absolute',
                    right:
                      bbox[0] + bbox[2] > imageDimensions[0] * 0.75
                        ? bbox[1] - labelHeight < 0 && bbox[3] + labelHeight > imageDimensions[1]
                          ? -(
                              Math.ceil(bbox[2] - settings.strokeWidth) -
                              (bbox[2] - settings.strokeWidth)
                            )
                          : 0
                        : undefined,
                    height: `${labelHeight}px`,
                    top:
                      bbox[1] - labelHeight < 0 && bbox[3] + labelHeight > imageDimensions[1]
                        ? -(settings.strokeWidth / 2)
                        : 0,
                    display: 'flex',
                  }}
                >
                  <span
                    style={{
                      color: getContrastTextColor(settings?.color),
                      fontSize: `${16 * imageRatio[1]}px`,
                      lineHeight: `${labelHeight}px`,
                    }}
                  >
                    {className}
                  </span>
                  <span
                    style={{
                      color: getContrastTextColor(settings?.color),
                      fontSize: `${16 * imageRatio[1]}px`,
                      lineHeight: `${labelHeight}px`,
                    }}
                  >
                    {(Number.parseFloat(confidence) * 100).toFixed(2)}%
                  </span>
                </div>
              </foreignObject>
            )}
          </g>
        );
      })}
      Sorry, your browser does not support inline SVG.
    </>
  );
};

import { ErrorBoundary } from 'react-error-boundary';
import { DatasetsDetailsImagesSlider2DBoxesOverlay } from './DatasetsDetailsImagesSlider2DOverlay';
import { DatasetsDetailsImagesSlider3DBoxesOverlay } from './DatasetsDetailsImagesSlider3DOverlay';
import { DatasetsDetailsImagesSliderMaskOverlay } from './DatasetsDetailsImagesSliderMaskOverlay';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';
import { DatasetsDetailsImagesSliderSegmentationOverlay } from './DatasetsDetailsImagesSliderSegmentationOverlay';

export const DatasetsDetailsImagesSliderOverlay = ({
  imageIndex,
  renderErrors,
  setRenderErrors,
}) => {
  const {
    groundTruthSettings: { color, mode, strokeWidth, visible, opacity, segmentationFill },
    groundTruthData,
  } = useImageOverlayContext();

  if (!visible) {
    return null;
  }
  if (mode === '2d') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-2d`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, mode]}
      >
        <DatasetsDetailsImagesSlider2DBoxesOverlay
          data={groundTruthData || null}
          strokeWidth={strokeWidth}
          color={color}
        />
      </ErrorBoundary>
    );
  }
  if (mode === '3d') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-3d`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, mode]}
      >
        <DatasetsDetailsImagesSlider3DBoxesOverlay
          data={groundTruthData || null}
          color={color}
          strokeWidth={strokeWidth}
        />
      </ErrorBoundary>
    );
  }
  if (mode === 'segmentation') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-${mode}`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            [`${imageIndex}-mask`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
        resetKeys={[imageIndex, mode]}
      >
        <DatasetsDetailsImagesSliderSegmentationOverlay
          color={color}
          data={groundTruthData || null}
          strokeWidth={strokeWidth}
          opacity={opacity}
          fill={segmentationFill}
        />
      </ErrorBoundary>
    );
  }
  if (mode === 'mask') {
    return (
      <ErrorBoundary
        fallback={<div />}
        onError={() => {
          setRenderErrors({
            [`${imageIndex}-${mode}`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            [`${imageIndex}-segmentation`]: {
              message: 'An error was encountered rendering annotations for this image.',
              type: 'error',
            },
            ...renderErrors,
          });
        }}
      >
        <DatasetsDetailsImagesSliderMaskOverlay data={groundTruthData || null} color={color} />
      </ErrorBoundary>
    );
  }
  return null;
};

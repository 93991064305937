import {
  Inference,
  MlInference,
  useGetDatasetInferencesQuery,
  useGetImageAnnotationLazyQuery,
  useGetImageInferenceLazyQuery,
} from '@generated/UseGraphqlHooks';
import { generateColorTriad } from '@helper-functions/color';
import { PropsWithChildren, createContext, useContext, useEffect, useState } from 'react';
import {
  GroundTruthSettings,
  ImageAnnotations,
  InferenceSettings,
} from './DatasetsDetailsImagesSliderTypes';

interface DatasetsDetailsImagesSliderContextProps {
  datasetInferences: MlInference[];
  groundTruthData: ImageAnnotations[];
  groundTruthSettings: GroundTruthSettings;
  inferenceSettings: InferenceSettings[];
  inferenceData: Record<string, Inference[]>;
  addInferenceLayer: () => void;
  removeInferenceLayer: (index: number) => void;
  updateGroundTruthSetting: (
    key: keyof GroundTruthSettings,
    value: GroundTruthSettings[keyof GroundTruthSettings],
  ) => void;
  updateInferenceSetting: (
    index: number,
    key: keyof InferenceSettings,
    value: InferenceSettings[keyof InferenceSettings],
  ) => void;
}

const DatasetsDetailsImagesSliderOverlayContext = createContext<
  DatasetsDetailsImagesSliderContextProps | undefined
>(undefined);

type DatasetsDetailsImagesSliderOverlayContextProviderProps = PropsWithChildren<{
  datasetId: string;
  filename: string;
  initialInferenceId: string;
  workspaceId: string;
}>;

export const DatasetsDetailsImagesSliderOverlayContextProvider = ({
  datasetId,
  filename,
  initialInferenceId,
  workspaceId,
  children,
}: DatasetsDetailsImagesSliderOverlayContextProviderProps) => {
  const [getImageAnnotation] = useGetImageAnnotationLazyQuery();
  const [getImageInference] = useGetImageInferenceLazyQuery();
  const { data } = useGetDatasetInferencesQuery({ variables: { workspaceId, datasetId } });
  const datasetInferences = data?.getMLInferences || [];

  const [groundTruthSettings, setGroundTruthSettings] = useState<GroundTruthSettings>(
    defaultGroundTruthSettings,
  );
  const [groundTruthData, setGroundTruthData] = useState<ImageAnnotations[]>([]);
  const [inferenceSettings, setInferenceSettings] = useState<InferenceSettings[]>(() =>
    initialInferenceId
      ? [
          {
            ...defaultInferenceSettings,
            inferenceId: initialInferenceId,
            color: generateColorTriad(groundTruthSettings.color)[0],
          },
        ]
      : [],
  );
  const [inferenceData, setInferenceData] = useState<Record<string, Inference[]>>({});

  const updateGroundTruthData = async () => {
    const { data } = await getImageAnnotation({
      variables: { filename, datasetId, workspaceId },
    });
    try {
      const parsedData = JSON.parse(data?.getImageAnnotation?.data?.[0] || '[]');
      setGroundTruthData(parsedData);
    } catch (e) {
      console.error(e);
    }
  };

  const updateInferenceData = async (inferenceId) => {
    const { data } = await getImageInference({
      variables: { filename, inferenceId, workspaceId },
    });
    try {
      setInferenceData((prev) => ({
        ...prev,
        [inferenceId]: data?.getImageInference?.inferences || [],
      }));
    } catch (e) {
      console.error(e);
    }
  };

  useEffect(() => {
    if (!filename) {
      return;
    }
    setGroundTruthData([]);
    setInferenceData({});
    void updateGroundTruthData();
    void Promise.all(inferenceSettings.map((s) => updateInferenceData(s.inferenceId)));
  }, [filename]);

  useEffect(() => {
    if (!inferenceSettings?.length) {
      return;
    }
    void Promise.all(inferenceSettings.map((s) => updateInferenceData(s.inferenceId)));
  }, [inferenceSettings]);

  const addInferenceLayer = () => {
    if (inferenceSettings.length >= 2) {
      return;
    }
    setInferenceSettings((currentLayers) => [
      ...currentLayers,
      {
        ...defaultInferenceSettings,
        color: generateColorTriad(groundTruthSettings.color)[currentLayers.length],
      },
    ]);
  };

  const removeInferenceLayer = (index: number) => {
    setInferenceSettings((currentLayers) => currentLayers.filter((_, i) => i !== index));
  };

  const updateGroundTruthSetting = (
    key: keyof GroundTruthSettings,
    vaule: GroundTruthSettings[keyof GroundTruthSettings],
  ) => {
    setGroundTruthSettings((current) => ({ ...current, [key]: vaule }));
  };

  const updateInferenceSetting = (
    index: number,
    key: keyof InferenceSettings,
    vaule: InferenceSettings[keyof InferenceSettings],
  ) => {
    setInferenceSettings((current) => {
      const newSettings = [...current];
      newSettings[index] = { ...newSettings[index], [key]: vaule };
      return newSettings;
    });
  };

  return (
    <DatasetsDetailsImagesSliderOverlayContext.Provider
      value={{
        datasetInferences,
        groundTruthSettings,
        inferenceData,
        inferenceSettings,
        groundTruthData,
        addInferenceLayer,
        removeInferenceLayer,
        updateGroundTruthSetting,
        updateInferenceSetting,
      }}
    >
      {children}
    </DatasetsDetailsImagesSliderOverlayContext.Provider>
  );
};

export const useImageOverlayContext = () => {
  return useContext(DatasetsDetailsImagesSliderOverlayContext);
};

const defaultGroundTruthSettings: GroundTruthSettings = {
  mode: '2d',
  color: '#ff0000',
  opacity: 1,
  strokeWidth: 2,
  visible: false,
};

const defaultInferenceSettings: InferenceSettings = {
  color: '#00ff00',
  confidenceThreshold: 50,
  inferenceId: '',
  opacity: 1,
  showLabels: false,
  strokeWidth: 2,
  visible: true,
};

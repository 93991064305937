import { useGraphqlPagination } from '@components/infiniteScroll';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import {
  GetDatasetImageFilenamesQuery,
  useGetDatasetImageFilenamesQuery,
  useGetDatasetImageLazyQuery,
} from '@generated/UseGraphqlHooks';
import { Container, Stack } from '@mui/material';
import { useEffect, useState } from 'react';
import { DatasetsDetailsImagesSliderOverlayContextProvider } from './DatasetsDetailsImagesSliderOverlayContext';
import { DatasetsDetailsImagesSliderOverlayDetails } from './DatasetsDetailsImagesSliderOverlayDetails';
import { ImageSelector } from './ImageSelector';
import { ImageViewer } from './ImageViewer';
import { OverlayControls } from './OverlayControls';

type DatasetsDetailsImagesSliderProps = {
  previewUrls?: string[];
  imageIndex?: number | null;
  workspaceId?: string;
  datasetId?: string;
  datasetName?: string;
  loading?: boolean;
  defaultInferenceId?: string;
  runs?: number;
};

export const DatasetsDetailsImagesSlider = ({
  imageIndex: initialImageIndex = 0,
  workspaceId,
  datasetId,
  datasetName,
  defaultInferenceId,
  runs,
}: DatasetsDetailsImagesSliderProps) => {
  const { data: filenames, fetchMore } = useGraphqlPagination<
    GetDatasetImageFilenamesQuery,
    string
  >(
    useGetDatasetImageFilenamesQuery({
      variables: { workspaceId, datasetId, path: 'images/', limit: 100 },
    }),
    'getDatasetFiles',
  );

  const [imageIndex, setImageIndex] = useState(initialImageIndex);
  const filename = filenames?.[imageIndex || 0] || null;
  const [imageData, setImageData] = useState<Record<number, string>>({});

  const [getDatasetImage] = useGetDatasetImageLazyQuery();

  useEffect(() => {
    if (!filenames) {
      return;
    }
    const loadImageData = async (index: number) => {
      if (index >= filenames.length) {
        await fetchMore();
        return;
      }
      const data = await getDatasetImage({
        variables: { workspaceId, datasetId, filename: filenames[index] },
      });
      const imageUrl = data?.data?.getDatasetImage?.data[0];
      if (!imageUrl) {
        return;
      }
      setImageData((old) => ({
        ...old,
        [index]: imageUrl,
      }));
    };
    if (!imageData[imageIndex]) {
      void loadImageData(imageIndex);
    }
    if (imageIndex < runs - 1 && !imageData[imageIndex + 1]) {
      void loadImageData(imageIndex + 1);
    }
    if (imageIndex > 0 && !imageData[imageIndex - 1]) {
      void loadImageData(imageIndex - 1);
    }
  }, [imageIndex, filename]);

  return (
    <DatasetsDetailsImagesSliderOverlayContextProvider
      datasetId={datasetId}
      filename={filename}
      initialInferenceId={defaultInferenceId}
      workspaceId={workspaceId}
    >
      <Stack
        data-cy="Datasets-Details-Images-Slider"
        sx={{ height: '100%', gap: 2, position: 'relative' }}
      >
        <Group alignItems="center" gap={4}>
          <Typography variant="subtitle1">{datasetName}</Typography>
          <ImageSelector
            filenames={filenames}
            selected={imageIndex}
            fetchMore={fetchMore}
            onSelect={setImageIndex}
          />
        </Group>
        <Group height="100%" width="100%">
          <ImageViewer
            imageIndex={imageIndex}
            url={imageData?.[imageIndex]}
            workspaceId={workspaceId}
            onPrevClick={imageIndex > 0 ? () => setImageIndex(imageIndex - 1) : undefined}
            onNextClick={imageIndex < runs - 1 ? () => setImageIndex(imageIndex + 1) : undefined}
          />
          <DatasetsDetailsImagesSliderOverlayDetails image={filenames?.[imageIndex] || ''} />
        </Group>
        <Container maxWidth="md" sx={{ mx: 'auto' }}>
          <OverlayControls />
        </Container>
      </Stack>
    </DatasetsDetailsImagesSliderOverlayContextProvider>
  );
};

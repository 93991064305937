import { Select } from '@components/form';
import { MenuItem, SelectChangeEvent } from '@mui/material';
import { UIEvent } from 'react';

export const ImageSelector = ({
  filenames,
  selected,
  fetchMore,
  onSelect,
}: {
  filenames: string[];
  selected: number;
  fetchMore: () => void;
  onSelect: (index: number) => void;
}) => {
  const onFetchMore = (event: UIEvent<HTMLElement>) => {
    if (
      event.currentTarget.scrollHeight ===
      event.currentTarget.scrollTop + event.currentTarget.clientHeight
    ) {
      void fetchMore();
    }
  };

  return (
    <Select
      name="dataset"
      value={selected}
      onChange={(e: SelectChangeEvent) => {
        onSelect(parseInt(e.target.value));
      }}
      placeholder="select"
      MenuProps={{
        slotProps: { paper: { onScroll: onFetchMore } },
      }}
      fullWidth={false}
      sx={{ width: 'fit-content' }}
    >
      {filenames.map((filename, index) => (
        <MenuItem value={index} key={filename}>
          {filename}
        </MenuItem>
      ))}
    </Select>
  );
};

import {
  EyeIcon,
  EyeOffIcon,
  Outline2DIcon,
  Outline3DIcon,
  OutlineInstanceIcon,
  TrashIcon,
} from '@assets/icons';
import { SlidersIcon } from '@assets/icons/SlidersIcon';
import { IconButton } from '@components/button';
import { Checkbox, ColorPicker, RadioIcon, Slider } from '@components/form';
import { Group } from '@components/layout';
import { Typography } from '@components/typography';
import { FormControlLabel, Menu, RadioGroup, Stack, useTheme } from '@mui/material';
import { useState } from 'react';
import { useImageOverlayContext } from './DatasetsDetailsImagesSliderOverlayContext';
import { GroundTruthSettings } from './DatasetsDetailsImagesSliderTypes';

export const GroundTruthSettingsRow = () => {
  const {
    groundTruthSettings: {
      color,
      mode,
      opacity,
      segmentationFill,
      segmentationMask,
      strokeWidth,
      visible,
    },
    updateGroundTruthSetting,
  } = useImageOverlayContext();
  const { typography } = useTheme();
  const [optionsMenuAnchorRef, setOptionsMenuAnchorRef] = useState<HTMLElement | null>(null);

  return (
    <Group alignItems="center" gap={4} py={1} maxWidth="md">
      <Typography variant="body2">Ground truth</Typography>
      <RadioGroup
        row
        sx={{
          flex: 1,
          gap: 6,
          justifyContent: 'center',
          '& .MuiFormControlLabel-label': { height: '24px', lineHeight: '24px' },
        }}
        value={mode}
        onChange={(e) =>
          updateGroundTruthSetting('mode', e.target.value as GroundTruthSettings['mode'])
        }
      >
        <RadioIcon value="2d" icon={Outline2DIcon}></RadioIcon>
        <RadioIcon value="3d" icon={Outline3DIcon}></RadioIcon>
        <RadioIcon value="segmentation" icon={OutlineInstanceIcon}></RadioIcon>
      </RadioGroup>
      <ColorPicker
        color={color}
        onChange={(newColor) => updateGroundTruthSetting('color', newColor)}
      />
      <IconButton
        Icon={SlidersIcon}
        size={24}
        onClick={(e) => setOptionsMenuAnchorRef(e.currentTarget)}
      />
      <Menu
        open={Boolean(optionsMenuAnchorRef)}
        anchorEl={optionsMenuAnchorRef}
        onClose={() => setOptionsMenuAnchorRef(null)}
        sx={{ '& .MuiList-root': { py: 3, px: 4 } }}
      >
        <Stack gap={1}>
          <Checkbox
            label="Segmentation mask"
            checked={segmentationMask}
            onClick={() => updateGroundTruthSetting('segmentationMask', !segmentationMask)}
            sx={{ '& .MuiTypography-root': { ...typography.caption2 } }}
            disabled={mode !== 'segmentation'}
          />
          <Checkbox
            label="Segmentation fill"
            checked={segmentationFill}
            onClick={() => updateGroundTruthSetting('segmentationFill', !segmentationFill)}
            sx={{ '& .MuiTypography-root': { ...typography.caption2 } }}
            disabled={mode !== 'segmentation'}
          />
          <FormControlLabel
            label="Fill opacity"
            control={
              <Slider
                min={0}
                max={1}
                step={0.05}
                value={opacity}
                onChange={(_, newOpacity) =>
                  updateGroundTruthSetting('opacity', newOpacity as number)
                }
                disabled={mode !== 'segmentation'}
              />
            }
            sx={{
              flexDirection: 'column-reverse',
              alignItems: 'flex-start',
              gap: 0,
              height: 'auto',
              '& .MuiTypography-root': { ...typography.caption2 },
            }}
          />
          <Stack>
            <Typography variant="caption2">Outline width</Typography>
            <Slider
              min={0.5}
              max={5}
              step={0.5}
              value={strokeWidth}
              onChange={(_, newWidth) =>
                updateGroundTruthSetting('strokeWidth', newWidth as number)
              }
            />
          </Stack>
        </Stack>
      </Menu>
      <IconButton
        Icon={visible ? EyeIcon : EyeOffIcon}
        size={24}
        onClick={() => updateGroundTruthSetting('visible', !visible)}
      />
      <IconButton Icon={TrashIcon} size={24} style={{ visibility: 'hidden' }} />
    </Group>
  );
};
